<template>
  <a-modal
    width="1200px"
    v-model="isShow"
    centered
    :mask-closable="false"
    :body-style="{ paddingLeft: 0 }"
    title="卡号详情"
  >
    <a-row type="flex" :gutter="24">
      <a-col flex="200px">
        <a-menu
          class="left-menus"
          mode="vertical"
          :default-selected-keys="['ShowSimCardBaseInfo']"
          @select="handleSelectMenu"
        >
          <a-menu-item
            v-for="item in tabs"
            :key="item.component"
          >
            <a-icon :type="item.icon" />
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </a-col>

      <a-col flex="1000px" class="right-content">
        <keep-alive>
          <component
            :is="currentComponent"
            :id="id"
          />
        </keep-alive>
      </a-col>
    </a-row>

    <template slot="footer">
      <a-button
        class="ant-btn-primary"
        @click="updateCarrierData"
        :loading="isUpdateBtnLoading"
      >
        更新
      </a-button>
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>
  </a-modal>
</template>

<script>

import { updateSimCardCarrierData } from '@/api/sim_card'

export default {
  name: 'ShowSimCard',
  components: {
    ShowSimCardBaseInfo: () => import('@/views/sim_cards/info/BaseInfo'),
    ShowSimCardCurrentPackage: () => import('@/views/sim_cards/info/CurrentPackage'),
    ShowSimCardFuturePackages: () => import('@/views/sim_cards/info/FuturePackages'),
    ShowSimCardHistoryPackages: () => import('@/views/sim_cards/info/HistoryPackages'),
    ShowSimCardDeviceAndUser: () => import('@/views/sim_cards/info/DeviceAndUser'),
    ShowSimCardNetworkStatistics: () => import('@/views/sim_cards/info/NetworkStatistics'),
    // ShowSimCardHistoryLocation: () => import('@/views/sim_cards/info/HistoryLocation'),
    ShowSimCardEvents: () => import('@/views/sim_cards/info/Events'),
    ShowSubCardInfo: () => import('@/views/sim_cards/info/SubCardInfo')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentComponent: 'ShowSimCardBaseInfo',
      isUpdateBtnLoading: false,
      tabs: [
        { component: 'ShowSimCardBaseInfo', name: '基本信息', icon: 'profile' },
        { component: 'ShowSimCardCurrentPackage', name: '当前套餐', icon: 'inbox' },
        { component: 'ShowSimCardFuturePackages', name: '后续套餐', icon: 'inbox' },
        { component: 'ShowSimCardHistoryPackages', name: '历史套餐', icon: 'inbox' },
        { component: 'ShowSubCardInfo', name: '融合子卡', icon: 'apartment' },
        { component: 'ShowSimCardDeviceAndUser', name: '设备与用户', icon: 'laptop' },
        { component: 'ShowSimCardNetworkStatistics', name: '网络数据', icon: 'line-chart' },
        // { component: 'ShowSimCardHistoryLocation', name: '历史轨迹', icon: 'dot-chart' },
        { component: 'ShowSimCardEvents', name: '历史事件', icon: 'history' }
      ]
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSelectMenu({ item, key, selectedKeys }) {
      this.currentComponent = key
    },

    // 更新运营商数据
    updateCarrierData() {
      this.isUpdateBtnLoading = true
      updateSimCardCarrierData(this.id).then((res) => {
        this.isUpdateBtnLoading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.left-menus {
  height: 100%;
}

.right-content {
  height: 500px;
  overflow: auto;
}
</style>
